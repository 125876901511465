import { Link } from 'gatsby'
import React from 'react'
import axios from 'axios'; 
import Container from '../components/container'
import BlockText from './block-text'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import Icon from './icons'

import styles from './locations.module.css'

function Card(props) {
  const { name, _rawAddress, mainImage } = props

  return (
    <div className={styles.card}>
      {mainImage &&
        (
          <div className={styles.image} style={{backgroundImage: `url(${imageUrlFor(buildImageObj(mainImage)).url()})`}}></div>
        )
      }
      <h5 className={`${styles.headline} ${styles.bullet}`}>{name}</h5>
      <div className={styles.address}>
        <BlockText blocks={_rawAddress} />
      </div>
    </div>
  )
}

class Locations extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      showForm: false,
      name: '',
      email: '',
      message: ''
    }
    this.toggleForm = this.toggleForm.bind(this)
  }

  toggleForm() {
    this.setState({ showForm: !this.state.showForm })
  }

  onChange(evt) {
    const obj = {};
    const field = evt.target.name
    obj[field] = evt.target.value;
    this.setState(obj)
  }

  handleSubmit(evt) {
    evt.preventDefault();
    const action = evt.target.action;
    const formFields = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    }
    const button = document.querySelector('[data-submit-button]');
    const successMessage = document.querySelector('[data-success-message]');
    const errorMessage = document.querySelector('[data-success-message]');
    button.classList.add(styles.loading);
    button.setAttribute('disabled', 'disabled');

    axios.post(action, formFields)
      .then(res => {
        if (res.status.toString().startsWith('4')) {
          errorMessage.classList.add(styles.show);
          button.classList.remove(styles.loading);
          button.setAttribute('disabled', '');
          return false;
        } else {
          button.classList.add(styles.hide);
          successMessage.classList.add(styles.show);
          return true;
        }
      }).catch(() => {
        errorMessage.classList.add(styles.show);
        button.classList.remove(styles.loading);
        button.setAttribute('disabled', '');
        return false;
      });
  }

  render() {
    const { locations, body } = this.props

    const highlightLocations = []
    const worldLocations = []

    locations.edges.map(location => {
      if (location.node.section.indexOf('highlight') > -1) {
        highlightLocations.push(location.node);
      }

      if (location.node.section.indexOf('world') > -1) {
        worldLocations.push(location.node);
      }
    });

    return (
      <div className={styles.root} id="next">
        <Container>
          <div className={styles.titleBar}>
            <div>
              {/* <h3 className={`${styles.headline} ${styles.bullet}`}>Our Locations</h3> */}
              <div className={styles.subtitle}><BlockText blocks={body} /></div>
            </div>
            <div>
              <span className={`${styles.button} ${styles.primary}`} onClick={this.toggleForm}>Get in touch with us</span>
            </div>
          </div>
        </Container>

        <div className={`${styles.formContainer} ${this.state.showForm ? styles.showForm : styles.hideForm}`}>
          <div className={styles.formBox}>
            <h5 className={`${styles.headline} ${styles.bullet}`}>Contact us</h5>
            <div className={styles.close} onClick={this.toggleForm}>
                <Icon symbol='close' />
            </div>
            <form action="https://getform.io/f/cab20dcb-af18-492d-a1b0-be4b7b16739f" method="POST" onSubmit={e => this.handleSubmit(e)}>
              <div className={styles.field}>
                <label>Name</label>
                <input type="text" name="name" onChange={e => this.onChange(e)} />
              </div>
              <div className={styles.field}>
                <label>Email</label>
                <input type="email" name="email" onChange={e => this.onChange(e)} />
              </div>
              <div className={styles.field}>
                <label>Message</label>
                <textarea name="message" onChange={e => this.onChange(e)} />
              </div>
              <button type="submit" data-submit-button className={`${styles.button} ${styles.primary}`}>Send</button>
              <div className={styles.success} data-success-message>
                Message sent
                <div className={styles.icon}><Icon symbol='tick' /></div>
              </div>
              <div className={styles.error} data-error-message>
                Error occurred
                <div className={styles.icon}><Icon symbol='close' /></div>
              </div>
            </form>
          </div>
        </div>

        <Container>
          <div className={`${styles.grid2}`}>
              {highlightLocations &&
                highlightLocations.map(node => (
                  <Card key={node._id} {...node} />
                ))
              }
          </div>
          <div className={styles.others}>
              <h4 className={`${styles.headline} ${styles.bullet}`}>Locations around the world</h4>
              <div className={`${styles.grid3}`}>
                {worldLocations &&
                  worldLocations.map(node => (
                    <Card key={node._id} {...node} />
                  ))
                }
              </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default Locations
